h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}
*, ::before, ::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  
  --tw-scroll-snap-strictness: proximity;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  border-color: var(--color__border-divider-light-1);
}
::before, ::after {
  --tw-content: '';
}
html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-feature-settings: normal;
  scroll-behavior: smooth;
  font-size: var(--type-font-base-size);
}
body {
  margin: 0;
  line-height: inherit;
  background-color: var(--color__bg-body-light);
  color: var(--color__text-light);
}
hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}
abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
a {
  color: inherit;
  text-decoration: inherit;
  text-decoration-line: underline;
  text-underline-offset: 1px;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}
b, strong {
  font-weight: bolder;
}
code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}
small {
  font-size: 80%;
}
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
  border-color: var(--color__border-divider-light-1);
  border-top-width: var(--sizes-border-width);
  border-right-width: var(--sizes-border-width);
}
button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}
button, select {
  text-transform: none;
}
button, [type='button'], [type='reset'], [type='submit'] {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}
:-moz-focusring {
  outline: auto;
}
:-moz-ui-invalid {
  box-shadow: none;
}
progress {
  vertical-align: baseline;
}
::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
summary {
  display: list-item;
}
blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}
fieldset {
  margin: 0;
  padding: 0;
}
legend {
  padding: 0;
}
ol, ul, menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
textarea {
  resize: vertical;
}
input::-moz-placeholder, textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
  color: #6b7280;
}
input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
  color: #6b7280;
}
button, [role="button"] {
  cursor: pointer;
}
:disabled {
  cursor: default;
}
img, svg, video, canvas, audio, iframe, embed, object {
  display: block;
  vertical-align: middle;
}
img, video {
  max-width: 100%;
  height: auto;
}
[hidden] {
  display: none;
}
[type='text'], [type='email'], [type='url'], [type='password'], [type='number'], [type='date'], [type='datetime-local'], [type='month'], [type='search'], [type='tel'], [type='time'], [type='week'], [multiple], textarea, select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  border-radius: 0px;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-shadow: 0 0 #0000;
}
[type='text']:focus, [type='email']:focus, [type='url']:focus, [type='password']:focus, [type='number']:focus, [type='date']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='week']:focus, [multiple]:focus, textarea:focus, select:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
}
::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}
::-webkit-date-and-time-value {
  min-height: 1.5em;
}
::-webkit-datetime-edit, ::-webkit-datetime-edit-year-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-minute-field, ::-webkit-datetime-edit-second-field, ::-webkit-datetime-edit-millisecond-field, ::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}
select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}
[multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  padding-right: 0.75rem;
  -webkit-print-color-adjust: unset;
  print-color-adjust: unset;
}
[type='checkbox'], [type='radio'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  --tw-shadow: 0 0 #0000;
}
[type='checkbox'] {
  border-radius: 0px;
}
[type='radio'] {
  border-radius: 100%;
}
[type='checkbox']:focus, [type='radio']:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
}
[type='checkbox']:checked, [type='radio']:checked {
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
[type='checkbox']:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}
[type='radio']:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}
[type='checkbox']:checked:hover, [type='checkbox']:checked:focus, [type='radio']:checked:hover, [type='radio']:checked:focus {
  border-color: transparent;
  background-color: currentColor;
}
[type='checkbox']:indeterminate {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
[type='checkbox']:indeterminate:hover, [type='checkbox']:indeterminate:focus {
  border-color: transparent;
  background-color: currentColor;
}
[type='file'] {
  background: unset;
  border-color: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
  font-size: unset;
  line-height: inherit;
}
[type='file']:focus {
  outline: 1px solid ButtonText;
  outline: 1px auto -webkit-focus-ring-color;
}
html, body, blockquote, dl, dd, hr, figure, p, pre, span {
  font-family: var(--type-font-body-family);
  font-style: var(--type-font-body-style);
  font-weight: var(--type-font-body-weight);
  line-height: 1.625;
  letter-spacing: 0em;
  font-size: 1rem;
  line-height: 1.5rem;
}
@media (min-width: 768px) {
  html, body, blockquote, dl, dd, hr, figure, p, pre, span {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}
html *, body *, blockquote *, dl *, dd *, hr *, figure *, p *, pre *, span * {
  font-size: 1rem;
  line-height: 1.5rem;
}
@media (min-width: 768px) {
  html *, body *, blockquote *, dl *, dd *, hr *, figure *, p *, pre *, span * {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}
h1, .h1, h1 > * {
  font-family: var(--type-font-header-family);
  font-style: var(--type-font-header-style);
  font-weight: var(--type-font-header-weight);
  font-size: 2.25rem;
  line-height: 2.5rem;
  letter-spacing: 0em;
  line-height: 1;
}
@media (min-width: 768px) {
  h1, .h1, h1 > * {
    font-size: 3rem;
    line-height: 1;
  }
}
h2, .h2, h2 > * {
  font-family: var(--type-font-header-family);
  font-style: var(--type-font-header-style);
  font-weight: var(--type-font-header-weight);
  font-size: 1.875rem;
  line-height: 2.25rem;
  letter-spacing: 0em;
  line-height: 1;
}
@media (min-width: 768px) {
  h2, .h2, h2 > * {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}
h3, .h3, h3 > * {
  font-family: var(--type-font-header-family);
  font-style: var(--type-font-header-style);
  font-weight: var(--type-font-header-weight);
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: 0em;
  line-height: 1;
}
@media (min-width: 768px) {
  h3, .h3, h3 > * {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}
h4, .h4, h4 > * {
  font-family: var(--type-font-header-family);
  font-style: var(--type-font-header-style);
  font-weight: var(--type-font-header-weight);
  font-size: 1.25rem;
  line-height: 1.75rem;
  letter-spacing: 0em;
  line-height: 1.25;
}
@media (min-width: 768px) {
  h4, .h4, h4 > * {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
h5, .h5, h5 > * {
  font-family: var(--type-font-header-family);
  font-style: var(--type-font-header-style);
  font-weight: var(--type-font-header-weight);
  font-size: 1.125rem;
  line-height: 1.75rem;
  letter-spacing: 0em;
  line-height: 1.25;
}
@media (min-width: 768px) {
  h5, .h5, h5 > * {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}
h6, .h6, h6 > * {
  font-family: var(--type-font-header-family);
  font-style: var(--type-font-header-style);
  font-weight: var(--type-font-header-weight);
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0em;
  line-height: 1.25;
}
@media (min-width: 768px) {
  h6, .h6, h6 > * {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}
input, input[type="text"], input[type="password"], input[type="email"], input[type="number"], input[type="url"], input[type="date"], input[type="datetime-local"], input[type="month"], input[type="week"], input[type="time"], input[type="search"], input[type="tel"], select, select[multiple], textarea, .form-input, .form-select {
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: var(--type-font-body-family);
  font-style: var(--type-font-body-style);
  font-weight: var(--type-font-body-weight);
  line-height: 1.625;
  letter-spacing: 0em;
}
label {
  cursor: pointer;
  font-family: var(--type-font-body-family);
  font-style: var(--type-font-body-style);
  font-weight: var(--type-font-body-weight);
  line-height: 1.625;
  letter-spacing: 0em;
  font-size: 0.75rem;
  line-height: 1rem;
}
@media (min-width: 768px) {
  label {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
label * {
  font-size: 0.75rem;
  line-height: 1rem;
}
@media (min-width: 768px) {
  label * {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
a:hover {
  text-decoration-line: underline;
  opacity: 0.5;
}
a:focus {
  box-shadow: var(--color__bg-body-light) 0px 0px 0px 2px,
        var(--color__border-selected-light-1) 0px 0px 0px 4px;
  outline: 2px solid transparent;
  outline-offset: 2px;
}
blockquote, dl, dd, h1, h2, h3, h4, h5, h6, figure, p, pre {
  margin-bottom: 0.5rem;
}
@media (min-width: 768px) {
  blockquote, dl, dd, h1, h2, h3, h4, h5, h6, figure, p, pre {
    margin-bottom: 1rem;
  }
}
ul {
  list-style-type: disc;
  padding-left: 1rem;
}
ol {
  list-style-type: decimal;
  padding-left: 1rem;
}
strong {
  font-weight: bold;
}
mark {
  background: var(--color-primary-button-background-light);
  font-weight: bold;
  color: #fafafa;
}
input[type="text"], input[type="password"], input[type="email"], input[type="number"], input[type="url"], input[type="date"], input[type="datetime-local"], input[type="month"], input[type="week"], input[type="time"], input[type="search"], input[type="tel"], select, select[multiple], textarea {
  min-height: 44px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  background-color: var(--color__bg-overlay-light-1);
  border-color: var(--color__border-divider-light-2);
  border-width: var(--sizes-border-width);
  border-radius: var(--sizes-border-input-radius);
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}
input[type="text"]::-moz-placeholder, input[type="password"]::-moz-placeholder, input[type="email"]::-moz-placeholder, input[type="number"]::-moz-placeholder, input[type="url"]::-moz-placeholder, input[type="date"]::-moz-placeholder, input[type="datetime-local"]::-moz-placeholder, input[type="month"]::-moz-placeholder, input[type="week"]::-moz-placeholder, input[type="time"]::-moz-placeholder, input[type="search"]::-moz-placeholder, input[type="tel"]::-moz-placeholder, select::-moz-placeholder, select[multiple]::-moz-placeholder, textarea::-moz-placeholder {
  opacity: 0.5;
  color: var(--color__text-light);
}
input[type="text"]::placeholder, input[type="password"]::placeholder, input[type="email"]::placeholder, input[type="number"]::placeholder, input[type="url"]::placeholder, input[type="date"]::placeholder, input[type="datetime-local"]::placeholder, input[type="month"]::placeholder, input[type="week"]::placeholder, input[type="time"]::placeholder, input[type="search"]::placeholder, input[type="tel"]::placeholder, select::placeholder, select[multiple]::placeholder, textarea::placeholder {
  opacity: 0.5;
  color: var(--color__text-light);
}
input[type="text"]:hover, input[type="password"]:hover, input[type="email"]:hover, input[type="number"]:hover, input[type="url"]:hover, input[type="date"]:hover, input[type="datetime-local"]:hover, input[type="month"]:hover, input[type="week"]:hover, input[type="time"]:hover, input[type="search"]:hover, input[type="tel"]:hover, select:hover, select[multiple]:hover, textarea:hover {
  border-color: var(--color__border-selected-light-1);
}
input[type="text"]:focus, input[type="password"]:focus, input[type="email"]:focus, input[type="number"]:focus, input[type="url"]:focus, input[type="date"]:focus, input[type="datetime-local"]:focus, input[type="month"]:focus, input[type="week"]:focus, input[type="time"]:focus, input[type="search"]:focus, input[type="tel"]:focus, select:focus, select[multiple]:focus, textarea:focus {
  background-color: var(--color__bg-body-light);
  border-color: var(--color__border-divider-light-2);
  box-shadow: var(--color__bg-body-light) 0px 0px 0px 2px,
        var(--color__border-selected-light-1) 0px 0px 0px 4px;
  outline: 2px solid transparent;
  outline-offset: 2px;
}
input[type="checkbox"] {
  cursor: pointer;
  background-color: var(--color__bg-overlay-light-1);
  color: var(--color__link-light);
  border-color: var(--color__border-divider-light-2);
  border-width: var(--sizes-border-width);
}
input[type="checkbox"]:checked {
  background-color: currentColor !important;
}
input[type="checkbox"]:hover {
  border-color: var(--color__border-selected-light-1);
}
input[type="checkbox"]:focus {
  border-color: var(--color__border-divider-light-2);
  box-shadow: var(--color__bg-body-light) 0px 0px 0px 2px,
        var(--color__border-selected-light-1) 0px 0px 0px 4px;
  outline: 2px solid transparent;
  outline-offset: 2px;
}
input[type="radio"] {
  cursor: pointer;
  border-radius: 9999px;
  background-color: var(--color__bg-overlay-light-1);
  color: var(--color__link-light);
  border-color: var(--color__border-divider-light-2);
  border-width: var(--sizes-border-width);
}
input[type="radio"]:checked {
  background-color: currentColor !important;
}
input[type="radio"]:hover {
  border-color: var(--color__border-selected-light-1);
}
input[type="radio"]:focus {
  border-color: var(--color__border-divider-light-2);
  box-shadow: var(--color__bg-body-light) 0px 0px 0px 2px,
        var(--color__border-selected-light-1) 0px 0px 0px 4px;
  outline: 2px solid transparent;
  outline-offset: 2px;
}
button:focus {
  box-shadow: var(--color__bg-body-light) 0px 0px 0px 2px,
        var(--color__border-selected-light-1) 0px 0px 0px 4px;
  outline: 2px solid transparent;
  outline-offset: 2px;
}
input[type="range"]::-webkit-slider-thumb {
  pointer-events: all;
  width: 24px;
  height: 24px;
  -webkit-appearance: none;
}
img {
  position: relative;
}
img:after {
  display: none;
  align-items: center;
  justify-content: center;
  color: var(--color__text-light);
  content: "" attr(alt) !important;
}
@media (min-width: 768px) {
  img:after {
    position: absolute;
    top: 0px;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    white-space: normal;
    padding: 1rem;
    text-align: center;
    font-size: 0.75rem;
    line-height: 1rem;
  }
  img:after * {
    font-size: 0.75rem;
    line-height: 1rem;
  }
}
::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  
  --tw-scroll-snap-strictness: proximity;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.pointer-events-none {
  pointer-events: none;
}
.\!visible {
  visibility: visible !important;
}
.visible {
  visibility: visible;
}
.collapse {
  visibility: collapse;
}
.\!fixed {
  position: fixed !important;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.\!sticky {
  position: sticky !important;
}
.sticky {
  position: sticky;
}
.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.inset-y-0 {
  top: 0px;
  bottom: 0px;
}
.\!top-4 {
  top: 1rem !important;
}
.-left-3 {
  left: -0.75rem;
}
.-right-3 {
  right: -0.75rem;
}
.-top-3 {
  top: -0.75rem;
}
.bottom-0 {
  bottom: 0px;
}
.bottom-12 {
  bottom: 3rem;
}
.bottom-2 {
  bottom: 0.5rem;
}
.bottom-4 {
  bottom: 1rem;
}
.bottom-\[50\%\] {
  bottom: 50%;
}
.bottom-auto {
  bottom: auto;
}
.left-0 {
  left: 0px;
}
.left-2 {
  left: 0.5rem;
}
.left-4 {
  left: 1rem;
}
.left-\[50\%\] {
  left: 50%;
}
.left-auto {
  left: auto;
}
.right-0 {
  right: 0px;
}
.right-2 {
  right: 0.5rem;
}
.right-4 {
  right: 1rem;
}
.right-\[10px\] {
  right: 10px;
}
.right-\[50\%\] {
  right: 50%;
}
.right-auto {
  right: auto;
}
.right-full {
  right: 100%;
}
.top-0 {
  top: 0px;
}
.top-1\/2 {
  top: 50%;
}
.top-12 {
  top: 3rem;
}
.top-4 {
  top: 1rem;
}
.top-\[10px\] {
  top: 10px;
}
.top-\[50\%\] {
  top: 50%;
}
.top-auto {
  top: auto;
}
.\!z-10 {
  z-index: 10 !important;
}
.\!z-20 {
  z-index: 20 !important;
}
.-z-10 {
  z-index: -10;
}
.z-10 {
  z-index: 10;
}
.z-20 {
  z-index: 20;
}
.z-30 {
  z-index: 30;
}
.z-40 {
  z-index: 40;
}
.z-50 {
  z-index: 50;
}
.z-\[1\] {
  z-index: 1;
}
.col-span-1 {
  grid-column: span 1 / span 1;
}
.col-span-2 {
  grid-column: span 2 / span 2;
}
.col-span-3 {
  grid-column: span 3 / span 3;
}
.col-span-4 {
  grid-column: span 4 / span 4;
}
.col-start-1 {
  grid-column-start: 1;
}
.col-start-4 {
  grid-column-start: 4;
}
.row-span-1 {
  grid-row: span 1 / span 1;
}
.row-span-2 {
  grid-row: span 2 / span 2;
}
.row-span-3 {
  grid-row: span 3 / span 3;
}
.row-span-4 {
  grid-row: span 4 / span 4;
}
.row-span-5 {
  grid-row: span 5 / span 5;
}
.row-span-6 {
  grid-row: span 6 / span 6;
}
.m-0 {
  margin: 0px;
}
.m-4 {
  margin: 1rem;
}
.m-auto {
  margin: auto;
}
.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}
.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.-ml-2 {
  margin-left: -0.5rem;
}
.-mr-12 {
  margin-right: -3rem;
}
.-mr-2 {
  margin-right: -0.5rem;
}
.-mr-6 {
  margin-right: -1.5rem;
}
.-mr-8 {
  margin-right: -2rem;
}
.-mt-16 {
  margin-top: -4rem;
}
.mb-0 {
  margin-bottom: 0px;
}
.mb-0\.5 {
  margin-bottom: 0.125rem;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-8 {
  margin-bottom: 2rem;
}
.ml-0 {
  margin-left: 0px;
}
.ml-1 {
  margin-left: 0.25rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.ml-4 {
  margin-left: 1rem;
}
.mr-1 {
  margin-right: 0.25rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mr-4 {
  margin-right: 1rem;
}
.mt-1 {
  margin-top: 0.25rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-28 {
  margin-top: 7rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mt-5 {
  margin-top: 1.25rem;
}
.mt-6 {
  margin-top: 1.5rem;
}
.mt-8 {
  margin-top: 2rem;
}
.mt-auto {
  margin-top: auto;
}
.block {
  display: block;
}
.\!inline-block {
  display: inline-block !important;
}
.inline-block {
  display: inline-block;
}
.inline {
  display: inline;
}
.\!flex {
  display: flex !important;
}
.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}
.table {
  display: table;
}
.grid {
  display: grid;
}
.contents {
  display: contents;
}
.\!hidden {
  display: none !important;
}
.hidden {
  display: none;
}
.aspect-\[1\/1\] {
  aspect-ratio: 1/1;
}
.aspect-\[1\\\/1\] {
  aspect-ratio: 1\/1;
}
.aspect-\[2\/3\] {
  aspect-ratio: 2/3;
}
.aspect-\[2\\\/3\] {
  aspect-ratio: 2\/3;
}
.aspect-video {
  aspect-ratio: 16 / 9;
}
.\!h-5 {
  height: 1.25rem !important;
}
.\!h-8 {
  height: 2rem !important;
}
.h-0 {
  height: 0px;
}
.h-1 {
  height: 0.25rem;
}
.h-14 {
  height: 3.5rem;
}
.h-2 {
  height: 0.5rem;
}
.h-3 {
  height: 0.75rem;
}
.h-32 {
  height: 8rem;
}
.h-4 {
  height: 1rem;
}
.h-5 {
  height: 1.25rem;
}
.h-8 {
  height: 2rem;
}
.h-96 {
  height: 24rem;
}
.h-\[50vh\] {
  height: 50vh;
}
.h-\[70vh\] {
  height: 70vh;
}
.h-\[inherit\] {
  height: inherit;
}
.h-auto {
  height: auto;
}
.h-full {
  height: 100%;
}
.max-h-\[100vh\] {
  max-height: 100vh;
}
.max-h-\[128px\] {
  max-height: 128px;
}
.max-h-\[24px\] {
  max-height: 24px;
}
.max-h-\[256px\] {
  max-height: 256px;
}
.max-h-\[32px\] {
  max-height: 32px;
}
.max-h-\[35px\] {
  max-height: 35px;
}
.max-h-\[500px\] {
  max-height: 500px;
}
.max-h-\[50vh\] {
  max-height: 50vh;
}
.max-h-\[600px\] {
  max-height: 600px;
}
.max-h-\[64px\] {
  max-height: 64px;
}
.max-h-\[70vh\] {
  max-height: 70vh;
}
.max-h-\[75vh\] {
  max-height: 75vh;
}
.max-h-\[80vh\] {
  max-height: 80vh;
}
.max-h-\[85vh\] {
  max-height: 85vh;
}
.max-h-full {
  max-height: 100%;
}
.\!min-h-0 {
  min-height: 0px !important;
}
.min-h-\[100vh\] {
  min-height: 100vh;
}
.min-h-\[16px\] {
  min-height: 16px;
}
.min-h-\[250px\] {
  min-height: 250px;
}
.min-h-\[300px\] {
  min-height: 300px;
}
.min-h-\[350px\] {
  min-height: 350px;
}
.min-h-\[450px\] {
  min-height: 450px;
}
.min-h-\[550px\] {
  min-height: 550px;
}
.min-h-\[650px\] {
  min-height: 650px;
}
.min-h-\[\] {}
.min-h-full {
  min-height: 100%;
}
.min-h-screen {
  min-height: 100vh;
}
.\!w-5 {
  width: 1.25rem !important;
}
.\!w-8 {
  width: 2rem !important;
}
.\!w-auto {
  width: auto !important;
}
.\!w-full {
  width: 100% !important;
}
.w-0 {
  width: 0px;
}
.w-1\/12 {
  width: 8.333333%;
}
.w-1\/2 {
  width: 50%;
}
.w-1\/4 {
  width: 25%;
}
.w-1\/5 {
  width: 20%;
}
.w-10\/12 {
  width: 83.333333%;
}
.w-11\/12 {
  width: 91.666667%;
}
.w-16 {
  width: 4rem;
}
.w-2 {
  width: 0.5rem;
}
.w-2\/12 {
  width: 16.666667%;
}
.w-2\/5 {
  width: 40%;
}
.w-3 {
  width: 0.75rem;
}
.w-3\/12 {
  width: 25%;
}
.w-3\/4 {
  width: 75%;
}
.w-3\/5 {
  width: 60%;
}
.w-4 {
  width: 1rem;
}
.w-4\/12 {
  width: 33.333333%;
}
.w-4\/5 {
  width: 80%;
}
.w-5 {
  width: 1.25rem;
}
.w-5\/12 {
  width: 41.666667%;
}
.w-6\/12 {
  width: 50%;
}
.w-7\/12 {
  width: 58.333333%;
}
.w-8 {
  width: 2rem;
}
.w-8\/12 {
  width: 66.666667%;
}
.w-9\/12 {
  width: 75%;
}
.w-\[20\%\] {
  width: 20%;
}
.w-\[200\%\] {
  width: 200%;
}
.w-\[25\%\] {
  width: 25%;
}
.w-\[40px\] {
  width: 40px;
}
.w-\[70px\] {
  width: 70px;
}
.w-auto {
  width: auto;
}
.w-full {
  width: 100%;
}
.w-screen {
  width: 100vw;
}
.\!min-w-0 {
  min-width: 0px !important;
}
.min-w-\[256px\] {
  min-width: 256px;
}
.min-w-\[300px\] {
  min-width: 300px;
}
.min-w-full {
  min-width: 100%;
}
.max-w-\[150px\] {
  max-width: 150px;
}
.max-w-\[256px\] {
  max-width: 256px;
}
.max-w-\[45\%\] {
  max-width: 45%;
}
.max-w-\[50\%\] {
  max-width: 50%;
}
.max-w-full {
  max-width: 100%;
}
.max-w-md {
  max-width: 28rem;
}
.max-w-none {
  max-width: none;
}
.max-w-prose {
  max-width: 65ch;
}
.max-w-xl {
  max-width: 36rem;
}
.max-w-xs {
  max-width: 20rem;
}
.flex-1 {
  flex: 1 1 0%;
}
.flex-none {
  flex: none;
}
.flex-shrink {
  flex-shrink: 1;
}
.shrink-0 {
  flex-shrink: 0;
}
.flex-grow {
  flex-grow: 1;
}
.grow {
  flex-grow: 1;
}
.grow-0 {
  flex-grow: 0;
}
.grow-\[7\] {
  flex-grow: 7;
}
.basis-0 {
  flex-basis: 0px;
}
.basis-\[10\%\] {
  flex-basis: 10%;
}
.origin-bottom-left {
  transform-origin: bottom left;
}
.origin-top-left {
  transform-origin: top left;
}
.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-x-8 {
  --tw-translate-x: -2rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-y-20 {
  --tw-translate-y: -5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-y-4 {
  --tw-translate-y: -1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-24 {
  --tw-translate-x: 6rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-8 {
  --tw-translate-x: 2rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-2 {
  --tw-translate-y: 0.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-4 {
  --tw-translate-y: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-8 {
  --tw-translate-y: 2rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}
.animate-ping {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}
@keyframes pulse {
  50% {
    opacity: .5;
  }
}
.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-zoom-in {
  cursor: zoom-in;
}
.resize {
  resize: both;
}
.snap-x {
  scroll-snap-type: x var(--tw-scroll-snap-strictness);
}
.snap-center {
  scroll-snap-align: center;
}
.list-disc {
  list-style-type: disc;
}
.list-none {
  list-style-type: none;
}
.appearance-none {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.auto-cols-auto {
  grid-auto-columns: auto;
}
.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.flex-row {
  flex-direction: row;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-col {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.content-center {
  align-content: center;
}
.content-start {
  align-content: flex-start;
}
.content-end {
  align-content: flex-end;
}
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.items-stretch {
  align-items: stretch;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-evenly {
  justify-content: space-evenly;
}
.justify-items-center {
  justify-items: center;
}
.gap-1 {
  gap: 0.25rem;
}
.gap-12 {
  gap: 3rem;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-20 {
  gap: 5rem;
}
.gap-4 {
  gap: 1rem;
}
.gap-7 {
  gap: 1.75rem;
}
.gap-x-1 {
  -moz-column-gap: 0.25rem;
  column-gap: 0.25rem;
}
.gap-y-0 {
  row-gap: 0px;
}
.gap-y-0\.5 {
  row-gap: 0.125rem;
}
.gap-y-8 {
  row-gap: 2rem;
}
.self-start {
  align-self: flex-start;
}
.self-end {
  align-self: flex-end;
}
.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-scroll {
  overflow: scroll;
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.overflow-y-hidden {
  overflow-y: hidden;
}
.scroll-smooth {
  scroll-behavior: smooth;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-ellipsis {
  text-overflow: ellipsis;
}
.whitespace-normal {
  white-space: normal;
}
.whitespace-nowrap {
  white-space: nowrap;
}
.break-words {
  overflow-wrap: break-word;
}
.\!rounded-none {
  border-radius: 0px !important;
}
.rounded-full {
  border-radius: 9999px;
}
.\!border-0 {
  border-width: 0px !important;
}
.border {
  border-width: 1px;
}
.\!border-b-0 {
  border-bottom-width: 0px !important;
}
.\!border-r-0 {
  border-right-width: 0px !important;
}
.\!border-rose-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(244 63 94 / var(--tw-border-opacity)) !important;
}
.\!border-transparent {
  border-color: transparent !important;
}
.\!border-white {
  --tw-border-opacity: 1 !important;
  border-color: rgb(255 255 255 / var(--tw-border-opacity)) !important;
}
.\!bg-transparent {
  background-color: transparent !important;
}
.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.bg-opacity-25 {
  --tw-bg-opacity: 0.25;
}
.bg-opacity-50 {
  --tw-bg-opacity: 0.5;
}
.bg-center {
  background-position: center;
}
.object-contain {
  -o-object-fit: contain;
  object-fit: contain;
}
.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}
.\!p-0 {
  padding: 0px !important;
}
.p-0 {
  padding: 0px;
}
.p-1 {
  padding: 0.25rem;
}
.p-2 {
  padding: 0.5rem;
}
.p-4 {
  padding: 1rem;
}
.p-8 {
  padding: 2rem;
}
.\!px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.\!px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.py-0\.5 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.\!pl-12 {
  padding-left: 3rem !important;
}
.\!pl-8 {
  padding-left: 2rem !important;
}
.\!pr-0 {
  padding-right: 0px !important;
}
.pb-12 {
  padding-bottom: 3rem;
}
.pb-14 {
  padding-bottom: 3.5rem;
}
.pb-2 {
  padding-bottom: 0.5rem;
}
.pb-4 {
  padding-bottom: 1rem;
}
.pb-8 {
  padding-bottom: 2rem;
}
.pl-0 {
  padding-left: 0px;
}
.pl-1 {
  padding-left: 0.25rem;
}
.pl-12 {
  padding-left: 3rem;
}
.pl-2 {
  padding-left: 0.5rem;
}
.pl-4 {
  padding-left: 1rem;
}
.pl-5 {
  padding-left: 1.25rem;
}
.pl-6 {
  padding-left: 1.5rem;
}
.pl-8 {
  padding-left: 2rem;
}
.pr-1 {
  padding-right: 0.25rem;
}
.pr-12 {
  padding-right: 3rem;
}
.pr-2 {
  padding-right: 0.5rem;
}
.pr-4 {
  padding-right: 1rem;
}
.pt-14 {
  padding-top: 3.5rem;
}
.pt-16 {
  padding-top: 4rem;
}
.pt-2 {
  padding-top: 0.5rem;
}
.pt-4 {
  padding-top: 1rem;
}
.pt-8 {
  padding-top: 2rem;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.align-middle {
  vertical-align: middle;
}
.align-bottom {
  vertical-align: bottom;
}
.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.capitalize {
  text-transform: capitalize;
}
.normal-case {
  text-transform: none;
}
.italic {
  font-style: italic;
}
.\!leading-tight {
  line-height: 1.25 !important;
}
.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}
.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}
.\!underline {
  text-decoration-line: underline !important;
}
.underline {
  text-decoration-line: underline;
}
.line-through {
  text-decoration-line: line-through;
}
.\!no-underline {
  text-decoration-line: none !important;
}
.no-underline {
  text-decoration-line: none;
}
.\!opacity-100 {
  opacity: 1 !important;
}
.\!opacity-75 {
  opacity: 0.75 !important;
}
.opacity-0 {
  opacity: 0;
}
.opacity-100 {
  opacity: 1;
}
.opacity-20 {
  opacity: 0.2;
}
.opacity-25 {
  opacity: 0.25;
}
.opacity-50 {
  opacity: 0.5;
}
.opacity-75 {
  opacity: 0.75;
}
.opacity-95 {
  opacity: 0.95;
}
.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.backdrop-blur-md {
  --tw-backdrop-blur: blur(12px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.color__bg-body {
  background-color: var(--color__bg-body-light);
}
.color__bg-text {
  background-color: var(--color__text-light);
}
.color__bg-overlay-1 {
  background-color: var(--color__bg-overlay-light-1);
}
.color__bg-overlay-2 {
  background-color: var(--color__bg-overlay-light-2);
}
.color__bg-overlay-3 {
  background-color: var(--color__bg-overlay-light-3);
}
.color__bg-primary {
  background-color: var(--color-primary-button-background-light);
}
.color__bg-secondary {
  background-color: var(--color-secondary-button-background-light);
}
.color__bg-tertiary {
  background-color: var(--color-tertiary-button-background-light);
}
.color__bg-neutral {
  background-color: var(--color-neutral-button-background-light);
}
.color__dark {
  color: var(--color__text-light);
}
.color__dark * {
  color: var(--color__text-light);
}
.color__light {
  color: var(--color__text-dark);
}
.color__light * {
  color: var(--color__text-dark);
}
.color__text {
  color: var(--color__text-light);
}
.color__primary {
  color: var(--color-primary-button-text-light);
}
.color__secondary {
  color: var(--color-secondary-button-text-light);
}
.color__tertiary {
  color: var(--color-tertiary-button-text-light);
}
.\!color__border-transparent {
  border-color: transparent !important;
}
.\!color__border-divider-1 {
  border-color: var(--color__border-divider-light-1) !important;
}
.color__border-divider-1 {
  border-color: var(--color__border-divider-light-1);
}
.color__border-divider-2 {
  border-color: var(--color__border-divider-light-2);
}
.color__border-selected-1 {
  border-color: var(--color__border-selected-light-1);
}
.border--width {
  border-width: var(--sizes-border-width);
}
.border--t-width {
  border-top-width: var(--sizes-border-width);
}
.border--r-width {
  border-right-width: var(--sizes-border-width);
}
.border--b-width {
  border-bottom-width: var(--sizes-border-width);
}
.border--l-width {
  border-left-width: var(--sizes-border-width);
}
.border--radius {
  border-radius: var(--sizes-border-element-radius);
}
@media (max-width: 768px) {
  .mobile-border--radius-t-0 {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .mobile-border--radius-b-0 {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.border--focus {
  box-shadow: var(--color__bg-body-light) 0px 0px 0px 2px,
        var(--color__border-selected-light-1) 0px 0px 0px 4px;
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.border__button--radius {
  border-radius: var(--sizes-border-button-radius);
}
.border__input--radius {
  border-radius: var(--sizes-border-input-radius);
}
.type__body {
  font-family: var(--type-font-body-family);
  font-style: var(--type-font-body-style);
  font-weight: var(--type-font-body-weight);
  line-height: 1.625;
  letter-spacing: 0em;
}
.type__body--bold {
  font-family: var(--type-font-body-family);
  font-weight: 700;
  line-height: 1.625;
  letter-spacing: 0em;
}
.type__heading {
  font-family: var(--type-font-header-family);
  font-style: var(--type-font-header-style);
  font-weight: var(--type-font-header-weight);
  letter-spacing: 0em;
  line-height: 1.25;
}
.type__heading--bold-2 {
  font-family: var(--type-font-header-family);
  font-style: var(--type-font-header-style);
  font-weight: var(--type-font-header-weight);
  font-size: 1.875rem;
  line-height: 2.25rem;
  letter-spacing: 0em;
  line-height: 1.25;
}
@media (min-width: 768px) {
  .type__heading--bold-2 {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}
.type__heading--bold-3 {
  font-family: var(--type-font-header-family);
  font-style: var(--type-font-header-style);
  font-weight: var(--type-font-header-weight);
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: 0em;
  line-height: 1.25;
}
@media (min-width: 768px) {
  .type__heading--bold-3 {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}
.type__heading--bold-5 {
  font-family: var(--type-font-header-family);
  font-style: var(--type-font-header-style);
  font-weight: var(--type-font-header-weight);
  font-size: 1.125rem;
  line-height: 1.75rem;
  letter-spacing: 0em;
  line-height: 1.25;
}
@media (min-width: 768px) {
  .type__heading--bold-5 {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}
.type__nav {
  font-family: var(--type-font-nav-family);
  font-style: var(--type-font-nav-style);
  font-weight: var(--type-font-nav-weight);
  line-height: 1.5;
  letter-spacing: 0.025em;
}
.type--smaller {
  font-size: 0.75rem;
  line-height: 1rem;
}
.type--smaller * {
  font-size: 0.75rem;
  line-height: 1rem;
}
.type--small {
  font-size: 0.75rem;
  line-height: 1rem;
}
@media (min-width: 768px) {
  .type--small {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
.type--small * {
  font-size: 0.75rem;
  line-height: 1rem;
}
@media (min-width: 768px) {
  .type--small * {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
.type--base {
  font-size: 1rem;
  line-height: 1.5rem;
}
@media (min-width: 768px) {
  .type--base {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}
.type--base * {
  font-size: 1rem;
  line-height: 1.5rem;
}
@media (min-width: 768px) {
  .type--base * {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}
.animation-100 {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 100ms;
}
.animation-300 {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}
.animation-500 {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}
.animation-500--all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}
.animation-1000 {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 1000ms;
}
.animation-5000--all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 5000ms;
}
.padding--t-gap {
  padding-top: var(--sizes-gap-width);
}
.padding--r-gap {
  padding-right: var(--sizes-gap-width);
}
.padding--b-gap {
  padding-bottom: var(--sizes-gap-width);
}
.padding--l-gap {
  padding-left: var(--sizes-gap-width);
}
.margin--t-gap {
  margin-top: var(--sizes-gap-width);
}
.margin--b-gap {
  margin-bottom: var(--sizes-gap-width);
}
.container {
  width: 100%;
}
@media (min-width: 360px) {
  .container {
    max-width: 360px;
  }
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.aspect-h-1 {
  --tw-aspect-h: 1;
}
.aspect-h-3 {
  --tw-aspect-h: 3;
}
.aspect-h-9 {
  --tw-aspect-h: 9;
}
.aspect-w-1 {
  position: relative;
  padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
  --tw-aspect-w: 1;
}
.aspect-w-1 > * {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.aspect-w-16 {
  position: relative;
  padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
  --tw-aspect-w: 16;
}
.aspect-w-16 > * {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.aspect-w-2 {
  position: relative;
  padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
  --tw-aspect-w: 2;
}
.aspect-w-2 > * {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.form-input, .form-textarea, .form-select, .form-multiselect {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  border-radius: 0px;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-shadow: 0 0 #0000;
}
.form-input:focus, .form-textarea:focus, .form-select:focus, .form-multiselect:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
}
.form-input::-moz-placeholder, .form-textarea::-moz-placeholder {
  color: #6b7280;
  opacity: 1;
}
.form-input::placeholder, .form-textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}
.form-input::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}
.form-input::-webkit-date-and-time-value {
  min-height: 1.5em;
}
.form-input::-webkit-datetime-edit, .form-input::-webkit-datetime-edit-year-field, .form-input::-webkit-datetime-edit-month-field, .form-input::-webkit-datetime-edit-day-field, .form-input::-webkit-datetime-edit-hour-field, .form-input::-webkit-datetime-edit-minute-field, .form-input::-webkit-datetime-edit-second-field, .form-input::-webkit-datetime-edit-millisecond-field, .form-input::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}
.form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}
.form-checkbox, .form-radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  --tw-shadow: 0 0 #0000;
}
.form-checkbox {
  border-radius: 0px;
}
.form-radio {
  border-radius: 100%;
}
.form-checkbox:focus, .form-radio:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
}
.form-checkbox:checked, .form-radio:checked {
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.form-checkbox:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}
.form-radio:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}
.form-checkbox:checked:hover, .form-checkbox:checked:focus, .form-radio:checked:hover, .form-radio:checked:focus {
  border-color: transparent;
  background-color: currentColor;
}
.form-checkbox:indeterminate {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.form-checkbox:indeterminate:hover, .form-checkbox:indeterminate:focus {
  border-color: transparent;
  background-color: currentColor;
}
.btn {
  display: inline-flex;
  min-height: 44px;
  width: auto;
  min-width: 44px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: center;
  text-decoration-line: none;
  background-color: var(--color-primary-button-background-light);
  border-color: var(--color-primary-button-border-light);
  color: var(--color-primary-button-text-light);
  border-width: var(--sizes-border-width);
  border-radius: var(--sizes-border-button-radius);
  font-family: var(--type-font-nav-family);
  font-style: var(--type-font-nav-style);
  font-weight: var(--type-font-nav-weight);
  line-height: 1.5;
  letter-spacing: 0.025em;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  text-transform: var(--type-button-capiliziation);
}
.btn * {
  color: var(--color-primary-button-text-light);
  font-family: var(--type-font-nav-family);
  font-style: var(--type-font-nav-style);
  font-weight: var(--type-font-nav-weight);
  line-height: 1.5;
  letter-spacing: 0.025em;
  text-transform: var(--type-button-capiliziation);
}
.btn__content {
  display: flex;
  align-items: center;
  font-family: var(--type-font-nav-family);
  font-style: var(--type-font-nav-style);
  font-weight: var(--type-font-nav-weight);
  line-height: 1.5;
  letter-spacing: 0.025em;
}
.btn--hover, .btn:hover {
  text-decoration-line: none;
  opacity: 0.75;
}
.btn--focus, .btn:focus {
  box-shadow: var(--color__bg-body-light) 0px 0px 0px 2px,
        var(--color__border-selected-light-1) 0px 0px 0px 4px;
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.btn--disabled, .btn:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.btn--small {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-size: 0.75rem;
  line-height: 1rem;
}
@media (min-width: 768px) {
  .btn--small {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
.btn--small * {
  font-size: 0.75rem;
  line-height: 1rem;
}
@media (min-width: 768px) {
  .btn--small * {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
.btn--small > * {
  font-size: 0.75rem;
  line-height: 1rem;
}
@media (min-width: 768px) {
  .btn--small > * {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
.btn--small > * * {
  font-size: 0.75rem;
  line-height: 1rem;
}
@media (min-width: 768px) {
  .btn--small > * * {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
.btn--smaller {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.75rem;
  line-height: 1rem;
}
.btn--smaller * {
  font-size: 0.75rem;
  line-height: 1rem;
}
.btn--smaller > * {
  font-size: 0.75rem;
  line-height: 1rem;
}
.btn--smaller > * * {
  font-size: 0.75rem;
  line-height: 1rem;
}
.btn--secondary {
  background-color: var(--color-secondary-button-background-light);
  border-color: var(--color-secondary-button-border-light);
  color: var(--color-secondary-button-text-light);
}
.btn--secondary * {
  color: var(--color-secondary-button-text-light);
}
.btn--tertiary {
  background-color: var(--color-tertiary-button-background-light);
  border-color: var(--color-tertiary-button-border-light);
  color: var(--color-tertiary-button-text-light);
}
.btn--tertiary * {
  color: var(--color-tertiary-button-text-light);
}
.btn--neutral {
  background-color: var(--color-neutral-button-background-light);
  border-color: var(--color-neutral-button-border-light);
  color: var(--color-neutral-button-text-light);
}
.btn--neutral * {
  color: var(--color-neutral-button-text-light);
}
.btn--plain {
  background-color: var(--color-plain-button-background-light);
  border-color: var(--color-plain-button-border-light);
  color: var(--color-plain-button-text-light);
}
.btn--plain * {
  color: var(--color-plain-button-text-light);
}
.btn--load {
  position: relative;
}
.btn--loading {
  cursor: wait;
}
.btn__spinner {
  visibility: hidden;
  position: absolute;
  left: 50%;
  top: 50%;
  display: flex;
  width: 91.666667%;
  --tw-translate-y: -50%;
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  align-items: center;
  justify-content: center;
  background-color: inherit;
}
.btn__spinner svg {
  margin: 0px;
  margin: auto;
  animation: spin 3s linear infinite;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.btn--loading .btn__content {
  visibility: hidden;
}
.btn--loading .btn__spinner {
  visibility: visible;
}
.window--narrow {
  margin: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media (min-width: 768px) {
  .window--narrow {
    max-width: 42rem;
  }
}
@media (min-width: 1024px) {
  .window--narrow {
    max-width: 48rem;
  }
}
@media (min-width: 1280px) {
  .window--narrow {
    max-width: 56rem;
  }
}
.\!window {
  margin: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media (min-width: 768px) {
  .\!window {
    max-width: 56rem;
  }
}
@media (min-width: 1024px) {
  .\!window {
    max-width: 64rem;
  }
}
@media (min-width: 1280px) {
  .\!window {
    max-width: 72rem;
  }
}
.window {
  margin: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media (min-width: 768px) {
  .window {
    max-width: 56rem;
  }
}
@media (min-width: 1024px) {
  .window {
    max-width: 64rem;
  }
}
@media (min-width: 1280px) {
  .window {
    max-width: 72rem;
  }
}
.window--wide {
  margin: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media (min-width: 768px) {
  .window--wide {
    max-width: 64rem;
  }
}
@media (min-width: 1024px) {
  .window--wide {
    max-width: 72rem;
  }
}
@media (min-width: 1280px) {
  .window--wide {
    max-width: 80rem;
  }
}
.window--full {
  margin: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}
.header__link:hover + .header__dropdown, .header__link:focus + .header__dropdown, .header__dropdown:hover, .header__dropdown:focus-within {
  height: auto;
  opacity: 1;
}
table p {
  margin: 0px;
}
table tr {
  border-color: var(--color__border-divider-light-1);
  border-bottom-width: var(--sizes-border-width);
}
table td {
  padding: 0.5rem;
  border-color: var(--color__border-divider-light-1);
  border-left-width: var(--sizes-border-width);
}
#stamped-main-widget {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0.5rem;
  padding-bottom: 1rem;
  opacity: 1;
  margin: 0px;
}
.stamped-header-title {
  font-family: var(--type-font-header-family);
  font-style: var(--type-font-header-style);
  font-weight: var(--type-font-header-weight);
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: 0em;
  line-height: 1;
  text-transform: uppercase;
  text-align: inherit;
}
@media (min-width: 768px) {
  .stamped-header-title {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}
div.stamped-container[data-widget-style], div[data-widget-style] div.stamped-container {
  margin: 0px !important;
  max-width: -moz-fit-content;
  max-width: fit-content;
}
.stamped-container {
  margin: 0px;
}
.stamped-summary-actions-newreview {
  display: inline-flex;
  min-height: 44px;
  width: auto;
  min-width: 44px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: center;
  text-decoration-line: none;
  background-color: var(--color-primary-button-background-light);
  border-color: var(--color-primary-button-border-light);
  color: var(--color-primary-button-text-light);
  border-width: var(--sizes-border-width);
  border-radius: var(--sizes-border-button-radius);
  font-family: var(--type-font-nav-family);
  font-style: var(--type-font-nav-style);
  font-weight: var(--type-font-nav-weight);
  line-height: 1.5;
  letter-spacing: 0.025em;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  text-transform: var(--type-button-capiliziation);
  background-color: var(--color-plain-button-background-light);
  border-color: var(--color-plain-button-border-light);
  color: var(--color-plain-button-text-light);
  position: relative;
  box-shadow: none !important;
}
.stamped-summary-actions-newreview * {
  color: var(--color-primary-button-text-light);
  font-family: var(--type-font-nav-family);
  font-style: var(--type-font-nav-style);
  font-weight: var(--type-font-nav-weight);
  line-height: 1.5;
  letter-spacing: 0.025em;
  text-transform: var(--type-button-capiliziation);
  color: var(--color-plain-button-text-light);
}
.stamped-summary-actions-newreview:hover {
  text-decoration-line: none;
  opacity: 0.75;
}
.stamped-summary-actions-newreview:focus {
  box-shadow: var(--color__bg-body-light) 0px 0px 0px 2px,
        var(--color__border-selected-light-1) 0px 0px 0px 4px;
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.stamped-summary-actions-newreview:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.stamped-summary-actions-newreview[data-shopify-xr-hidden] {
  display: none;
}
@media screen and (max-width: 749px) {
  .stamped-summary-actions-newreview:not([data-shopify-xr-hidden]) {
    display: inline-flex;
  }
}
.stamped-product-reviews-badge .stamped-main-badge {
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: var(--color__bg-body-light);
  color: var(--color__text-light);
  border-color: transparent !important;
  font-size: 0.75rem;
  line-height: 1rem;
}
.stamped-product-reviews-badge .stamped-main-badge * {
  font-size: 0.75rem;
  line-height: 1rem;
}
.stamped-badge {
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: var(--color__bg-body-light);
  color: var(--color__text-light);
  border-color: transparent !important;
  font-size: 0.75rem;
  line-height: 1rem;
}
.stamped-badge * {
  font-size: 0.75rem;
  line-height: 1rem;
}
[x-cloak] {
  display: none;
}
.m-0--clear p {
  margin: 0px;
}
.strikethrough {
  position: relative;
}
.strikethrough:before {
  position: absolute;
  left: 0px;
  right: 0px;
  opacity: 0.5;
  content: "";
  top: 50%;
  border-top: 2px solid;
  border-color: var(--color__text-light) !important;
  transform: rotate(-15deg);
}
.hidescrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.hidescrollbar::-webkit-scrollbar {
  display: none;
}
.fi {
  position: relative;
  display: inline-block;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding-right: 0.5rem;
  width: 1.33333333em;
  line-height: 1em;
}
.fi:before {
  content: "\00a0";
}
.btn[data-shopify-xr-hidden] {
  display: none;
}
@media screen and (max-width: 749px) {
  .btn:not([data-shopify-xr-hidden]) {
    display: inline-flex;
  }
}
.gmnoprint {
  min-height: 28px;
}
.gm-style-cc {
  min-height: 28px;
}
.login a {
  display: block;
  margin: 0 auto;
  width: -moz-fit-content;
  width: fit-content;
}
.login a[href='#recover'] {
  margin-left: 0;
  margin-right: 0;
}
.login .field + a {
  margin-top: 1rem;
}
.login p {
  margin: 1.5rem 0;
}
.login h3 {
  margin-top: 1.5rem;
  text-align: left;
  font-size: calc(var(--font-heading-scale) * 1.6rem);
}
#customer_login_guest button {
  margin-top: 0;
}
#recover, #recover + div {
  display: none;
}
#recover:target {
  display: block;
}
#recover:target + div {
  display: block;
}
#recover:target ~ #login, #recover:target ~ #login + div {
  display: none;
}
#recover, #login {
  scroll-margin-top: 20rem;
}
#recover {
  margin-bottom: 0;
}
.shopify-challenge__container, .shopify-policy__container {
  padding-top: 10rem;
  padding-bottom: 10rem;
}
[data-shopify-buttoncontainer] {
  justify-content: center !important;
}
[data-shopify-buttoncontainer] li {
  flex-grow: 1 !important;
}
.shopify-payment-button__button {
  font-family: var(--type-font-nav-family);
  font-style: var(--type-font-nav-style);
  font-weight: var(--type-font-nav-weight);
  line-height: 1.5;
  letter-spacing: 0.025em;
}
.shopify-payment-button__button--hidden {
  display: none !important;
}
.shopify-payment-button__button, .shopify-payment-button__more-options {
  overflow: hidden;
  border-radius: var(--sizes-border-button-radius) !important;
}
.shopify-payment-button__button--unbranded {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  background-color: var(--color-primary-button-background-light) !important;
  color: var(--color-primary-button-text-light) !important;
  line-height: 1.5 !important;
  letter-spacing: 0.025em !important;
}
@media (hover: hover) and (pointer: fine) {
  .shopify-payment-button__button--unbranded:hover {
    opacity: 0.75;
    background-color: var(--color-primary-button-background-light);
  }
  .shopify-payment-button__button--branded:hover {
    opacity: 0.75;
    background-color: var(--color-secondary-button-background-light);
  }
}
.shopify-payment-button__button--branded {
  line-height: 1.5 !important;
  letter-spacing: 0.025em !important;
}
.shopify-payment-button__button [role="button"] {
  min-height: 0px;
  padding: 0px;
}
.shopify-payment-button__button:focus-within {
  box-shadow: var(--color__bg-body-light) 0px 0px 0px 2px,
        var(--color__border-selected-light-1) 0px 0px 0px 4px !important;
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
}
.shopify-payment-button__more-options {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: var(--color__bg-overlay-light-1) !important;
}
.after\:pl-1::after {
  content: var(--tw-content);
  padding-left: 0.25rem;
}
.after\:content-\[\'\\007C\'\]::after {
  --tw-content: '\007C';
  content: var(--tw-content);
}
.after\:content-\[\'\\00B7\'\]::after {
  --tw-content: '\00B7';
  content: var(--tw-content);
}
.after\:color__bg-body::after {
  content: var(--tw-content);
  background-color: var(--color__bg-body-light);
}
.after\:color__bg-overlay-1::after {
  content: var(--tw-content);
  background-color: var(--color__bg-overlay-light-1);
}
.after\:color__bg-overlay-2::after {
  content: var(--tw-content);
  background-color: var(--color__bg-overlay-light-2);
}
.after\:color__bg-overlay-3::after {
  content: var(--tw-content);
  background-color: var(--color__bg-overlay-light-3);
}
.after\:color__bg-primary::after {
  content: var(--tw-content);
  background-color: var(--color-primary-button-background-light);
}
.after\:color__bg-secondary::after {
  content: var(--tw-content);
  background-color: var(--color-secondary-button-background-light);
}
.after\:color__bg-neutral::after {
  content: var(--tw-content);
  background-color: var(--color-neutral-button-background-light);
}
.after\:color__text::after {
  content: var(--tw-content);
  color: var(--color__text-light);
}
.after\:color__primary::after {
  content: var(--tw-content);
  color: var(--color-primary-button-text-light);
}
.after\:color__secondary::after {
  content: var(--tw-content);
  color: var(--color-secondary-button-text-light);
}
.last\:after\:hidden:last-child::after {
  content: var(--tw-content);
  display: none;
}
.focus-within\:block:focus-within {
  display: block;
}
@media (hover: hover) and (pointer: fine) {
  .hover\:block:hover {
    display: block;
  }
  .hover\:cursor-default:hover {
    cursor: default;
  }
  .hover\:cursor-pointer:hover {
    cursor: pointer;
  }
  .hover\:underline:hover {
    text-decoration-line: underline;
  }
  .hover\:no-underline:hover {
    text-decoration-line: none;
  }
  .hover\:opacity-100:hover {
    opacity: 1;
  }
  .hover\:opacity-75:hover {
    opacity: 0.75;
  }
  .hover\:color__bg-overlay-1:hover {
    background-color: var(--color__bg-overlay-light-1);
  }
  .hover\:color__border-selected-1:hover {
    border-color: var(--color__border-selected-light-1);
  }
}
.focus\:-mr-\[4rem\]:focus {
  margin-right: -4rem;
}
.focus\:translate-y-0:focus {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.focus\:rounded-r-\[50px\]:focus {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.group:focus-within .group-focus-within\:translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.group:focus-within .group-focus-within\:opacity-100 {
  opacity: 1;
}
@media (hover: hover) and (pointer: fine) {
  .group:hover .group-hover\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .group:hover .group-hover\:opacity-0 {
    opacity: 0;
  }
  .group:hover .group-hover\:opacity-100 {
    opacity: 1;
  }
  .group:hover .group-hover\:border__button--focus {
    box-shadow: var(--color__bg-body-light) 0px 0px 0px 2px,
          var(--color__border-selected-light-1) 0px 0px 0px 4px;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
}
.peer:focus ~ .peer-focus\:block {
  display: block;
}
@media (min-width: 640px) {
  .sm\:block {
    display: block;
  }
  .sm\:max-w-\[70\%\] {
    max-width: 70%;
  }
  .sm\:p-0 {
    padding: 0px;
  }
}
@media (min-width: 768px) {
  .md\:absolute {
    position: absolute;
  }
  .md\:bottom-auto {
    bottom: auto;
  }
  .md\:left-4 {
    left: 1rem;
  }
  .md\:left-5 {
    left: 1.25rem;
  }
  .md\:left-auto {
    left: auto;
  }
  .md\:right-4 {
    right: 1rem;
  }
  .md\:right-5 {
    right: 1.25rem;
  }
  .md\:top-1\/2 {
    top: 50%;
  }
  .md\:top-5 {
    top: 1.25rem;
  }
  .md\:z-30 {
    z-index: 30;
  }
  .md\:col-span-1 {
    grid-column: span 1 / span 1;
  }
  .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }
  .md\:col-span-3 {
    grid-column: span 3 / span 3;
  }
  .md\:col-span-4 {
    grid-column: span 4 / span 4;
  }
  .md\:col-span-5 {
    grid-column: span 5 / span 5;
  }
  .md\:col-span-6 {
    grid-column: span 6 / span 6;
  }
  .md\:mr-0 {
    margin-right: 0px;
  }
  .md\:block {
    display: block;
  }
  .md\:flex {
    display: flex;
  }
  .md\:table-cell {
    display: table-cell;
  }
  .md\:grid {
    display: grid;
  }
  .md\:hidden {
    display: none;
  }
  .md\:h-\[4rem\] {
    height: 4rem;
  }
  .md\:h-\[500px\] {
    height: 500px;
  }
  .md\:max-h-\[400px\] {
    max-height: 400px;
  }
  .md\:max-h-\[80vh\] {
    max-height: 80vh;
  }
  .md\:max-h-full {
    max-height: 100%;
  }
  .md\:min-h-\[350px\] {
    min-height: 350px;
  }
  .md\:min-h-\[450px\] {
    min-height: 450px;
  }
  .md\:min-h-\[550px\] {
    min-height: 550px;
  }
  .md\:min-h-\[60vh\] {
    min-height: 60vh;
  }
  .md\:min-h-\[70vh\] {
    min-height: 70vh;
  }
  .md\:min-h-\[80vh\] {
    min-height: 80vh;
  }
  .md\:min-h-full {
    min-height: 100%;
  }
  .md\:\!w-auto {
    width: auto !important;
  }
  .md\:w-1\/12 {
    width: 8.333333%;
  }
  .md\:w-1\/2 {
    width: 50%;
  }
  .md\:w-1\/3 {
    width: 33.333333%;
  }
  .md\:w-1\/4 {
    width: 25%;
  }
  .md\:w-10\/12 {
    width: 83.333333%;
  }
  .md\:w-11\/12 {
    width: 91.666667%;
  }
  .md\:w-2 {
    width: 0.5rem;
  }
  .md\:w-2\/12 {
    width: 16.666667%;
  }
  .md\:w-2\/3 {
    width: 66.666667%;
  }
  .md\:w-3\/12 {
    width: 25%;
  }
  .md\:w-3\/4 {
    width: 75%;
  }
  .md\:w-4\/12 {
    width: 33.333333%;
  }
  .md\:w-5\/12 {
    width: 41.666667%;
  }
  .md\:w-6\/12 {
    width: 50%;
  }
  .md\:w-7\/12 {
    width: 58.333333%;
  }
  .md\:w-8\/12 {
    width: 66.666667%;
  }
  .md\:w-9\/12 {
    width: 75%;
  }
  .md\:w-96 {
    width: 24rem;
  }
  .md\:w-\[26\%\] {
    width: 26%;
  }
  .md\:w-auto {
    width: auto;
  }
  .md\:w-full {
    width: 100%;
  }
  .md\:max-w-\[80vw\] {
    max-width: 80vw;
  }
  .md\:max-w-md {
    max-width: 28rem;
  }
  .md\:basis-\[90\%\] {
    flex-basis: 90%;
  }
  .md\:-translate-y-1\/2 {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .md\:flex-nowrap {
    flex-wrap: nowrap;
  }
  .md\:justify-start {
    justify-content: flex-start;
  }
  .md\:justify-end {
    justify-content: flex-end;
  }
  .md\:gap-4 {
    gap: 1rem;
  }
  .md\:gap-x-16 {
    -moz-column-gap: 4rem;
    column-gap: 4rem;
  }
  .md\:gap-y-0 {
    row-gap: 0px;
  }
  .md\:border-t-0 {
    border-top-width: 0px;
  }
  .md\:\!px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .md\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .md\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .md\:pb-0 {
    padding-bottom: 0px;
  }
  .md\:pb-4 {
    padding-bottom: 1rem;
  }
  .md\:pr-4 {
    padding-right: 1rem;
  }
  .md\:text-right {
    text-align: right;
  }
  .md\:align-middle {
    vertical-align: middle;
  }
  .md\:border--r-width {
    border-right-width: var(--sizes-border-width);
  }
  .md\:border--l-width {
    border-left-width: var(--sizes-border-width);
  }
  .md\:margin--r-gap {
    margin-right: var(--sizes-gap-width);
  }
  .md\:margin--l-gap {
    margin-left: var(--sizes-gap-width);
  }
}
@media (min-width: 1024px) {
  .lg\:flex {
    display: flex;
  }
  .lg\:h-full {
    height: 100%;
  }
  .lg\:w-1\/2 {
    width: 50%;
  }
  .lg\:w-1\/4 {
    width: 25%;
  }
  .lg\:text-left {
    text-align: left;
  }
}